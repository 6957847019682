<template>
  <v-navigation-drawer stateless permanent fixed app dark width="60" class="layout-sidebar pb-3">
    <v-list ref="sidebarList" background-color="transparent" class="mt-10 mb-auto layout-sidebar__nav">
      <div class="layout-sidebar__nav--slider" :style="{ transform: `translateY(${sliderPosition}px)` }"></div>
      <v-tooltip v-for="route in routes" :key="route.id" right content-class="layout-sidebar__tooltip py-2 px-3">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item :ref="route.id" :to="route.to" v-bind="attrs" class="layout-sidebar__item px-0" v-on="on">
            <v-icon v-if="isString(route.icon)" color="#fff" class="me-0">{{ route.icon }}</v-icon>
            <component :is="route.icon" v-else />

            <v-avatar
              v-if="route.badge"
              size="20"
              class="layout-sidebar__badge ml-2 d-flex align-center justify-center"
              color="secondary"
            >
              <span class="primary--text text-subtitle-2">
                {{ trimBadge(route.badge) }}
              </span>
            </v-avatar>
          </v-list-item>
        </template>

        <span>{{ route.text }}</span>
      </v-tooltip>

      <div class="mt-auto">
        <layout-sidebar-language
          v-if="!languagesListLoading"
          v-model="languageModel"
          :languages="languagesList"
        ></layout-sidebar-language>

        <!-- <v-tooltip right content-class="layout-sidebar__tooltip">
          <template #activator="{ on: onTooltip }">
            <v-btn class="layout-sidebar__item px-0" icon width="60px" v-on="{ ...onTooltip }">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.13203 13.63C1.95265 12.5507 1.95265 11.4493 2.13203 10.37C3.23403 10.396 4.22403 9.86801 4.60903 8.93901C4.99403 8.00901 4.66703 6.93501 3.86903 6.17601C4.50544 5.2853 5.28459 4.50581 6.17503 3.86901C6.93503 4.66701 8.00903 4.99401 8.93903 4.60901C9.86903 4.22401 10.396 3.23301 10.369 2.13201C11.4489 1.95242 12.5511 1.95242 13.631 2.13201C13.604 3.23401 14.132 4.22401 15.061 4.60901C15.991 4.99401 17.065 4.66701 17.824 3.86901C18.7147 4.50542 19.4942 5.28458 20.131 6.17501C19.333 6.93501 19.006 8.00901 19.391 8.93901C19.776 9.86901 20.767 10.396 21.868 10.369C22.0476 11.4489 22.0476 12.5511 21.868 13.631C20.766 13.604 19.776 14.132 19.391 15.061C19.006 15.991 19.333 17.065 20.131 17.824C19.4946 18.7147 18.7155 19.4942 17.825 20.131C17.065 19.333 15.991 19.006 15.061 19.391C14.131 19.776 13.604 20.767 13.631 21.868C12.5511 22.0476 11.4489 22.0476 10.369 21.868C10.396 20.766 9.86803 19.776 8.93903 19.391C8.00903 19.006 6.93503 19.333 6.17603 20.131C5.28532 19.4946 4.50583 18.7154 3.86903 17.825C4.66703 17.065 4.99403 15.991 4.60903 15.061C4.22403 14.131 3.23303 13.604 2.13203 13.631V13.63ZM12 15C12.7957 15 13.5587 14.6839 14.1213 14.1213C14.684 13.5587 15 12.7957 15 12C15 11.2044 14.684 10.4413 14.1213 9.87869C13.5587 9.31608 12.7957 9.00001 12 9.00001C11.2044 9.00001 10.4413 9.31608 9.87871 9.87869C9.3161 10.4413 9.00003 11.2044 9.00003 12C9.00003 12.7957 9.3161 13.5587 9.87871 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
                />
              </svg>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip> -->

        <layout-sidebar-profile></layout-sidebar-profile>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// node_modules
import { mapGetters, mapActions } from 'vuex';

// Services
import analyticsService from '@/services/analytics';

// Constants
import * as routes from '@/constants/routes';
import { LOGIN, MAIN } from '@/constants/routes';
import { CHANGE_LANGUAGE } from '@/constants/analyticsActions';

// Components
import LayoutSidebarLanguage from './Language.vue';
import LayoutSidebarProfile from './Profile.vue';

export default {
  name: 'LayoutSidebar',
  components: {
    LayoutSidebarLanguage,
    LayoutSidebarProfile,
  },

  props: {
    hasProfile: { type: Boolean, default: false },
    routes: { type: Array, default: () => [] },
  },

  data: () => ({
    isMounted: false,
    iconIndex: 0,
  }),

  computed: {
    ...mapGetters(['selectedLanguage', 'languagesList', 'languagesListLoading']),

    homeLink() {
      return this.hasProfile ? { name: MAIN } : { name: LOGIN };
    },

    isProfilePage() {
      return this.$route.name === routes.PROFILE;
    },

    sliderPosition() {
      let pos = 0;

      if (!this.isMounted) return pos;

      if (this.isProfilePage) {
        pos = this.$refs.sidebarList.$el.getBoundingClientRect().height - 40;
      } else {
        const scrollPosition = 100 - this.$refs.sidebarList.$el.getBoundingClientRect().top;

        this.routes.forEach(route => {
          if (this.$route.matched.find(match => match.name === route.to.name)) {
            const routePosTop = this.$refs[route.id][0].$el.getBoundingClientRect().top;
            pos = routePosTop - 100 + scrollPosition;
          }
        });
      }

      return pos;
    },

    languageModel: {
      get() {
        return this.selectedLanguage;
      },

      set(language) {
        analyticsService.track(CHANGE_LANGUAGE, analyticsService.createChangeLanguagePayload(language.code));
        this.selectLanguage(language);
      },
    },

    showIcon() {
      return this.icons[this.iconIndex];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    ...mapActions(['selectLanguage']),

    isString(icon) {
      return typeof icon === 'string';
    },

    trimBadge(value) {
      return value > 9 ? '9+' : value;
    },
  },
};
</script>

<style lang="scss">
.layout-sidebar {
  background: $--blue-color-20 !important;

  &__nav {
    position: relative;
    flex: 1 1 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
  }

  .layout-sidebar__nav--slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 4px;
    background: #fff;

    will-change: transform;
    transition: transform 0.35s;
  }

  &__item {
    flex: 0 0 40px !important;
    height: 40px !important;
    min-width: auto !important;
    min-height: auto !important;
    color: #fff !important;
    border-radius: 0 !important;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &::before {
      opacity: 0 !important;
    }

    &:hover::before {
      opacity: 0.15 !important;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: #fff;
      opacity: 0;

      transition: opacity 0.3s;
    }

    &--active::after {
      opacity: 1;
    }
  }

  &__tooltip {
    font-weight: 500 !important;
    background: #fff !important;
    color: #1e1e22 !important;
    filter: drop-shadow(0px 6px 16px rgba(26, 28, 37, 0.08)) drop-shadow(0px 0px 1px rgba(26, 28, 37, 0.24)) !important;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-right: 6px solid #fff;
    }
  }

  &__badge {
    position: absolute !important;
    top: 5px;
    right: 10px;
  }

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
